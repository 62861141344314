// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
// import "firebase/storage";
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdcIzeBg5OiHqnf-DNAAeUHcr_N48YkjA",
  authDomain: "milesbd-3e58b.firebaseapp.com",
  databaseURL: "https://milesbd-3e58b.firebaseio.com",
  projectId: "milesbd-3e58b",
  storageBucket: "milesbd-3e58b.appspot.com",
  messagingSenderId: "224051454125",
  appId: "1:224051454125:web:c5e49dc20e00cd2692e624",
  measurementId: "G-4ZPV9VKSGE"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.auth = app.auth();
    this.db = app.database();
    // this.storage = app.storage();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.analytics = app.analytics();
  }
  // *** Auth API - Create Account ***
  // doCreateUserWithEmailAndPassword = (email, password) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);

  // *** Auth API - EmailAuthProvider ***
  // EmailAuthProviderCredential = (email, password) =>
  //   this.emailAuthProvider.credential(email, password);

  // *** Auth API - Sign In ***
  // doSignInWithEmailAndPassword = (email, password) =>
  //   this.auth.signInWithEmailAndPassword(email, password);

  // *** AUTH API - Sign In With Google ***
  // doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  // *** Auth API - UpdateUser
  // updateProfile = ({ displayName, phoneNumber }) =>
  //   this.auth.currentUser.updateProfile({
  //     displayName: displayName,
  //     phoneNumber: phoneNumber,
  //   });

  // *** AUTH API - currentUser
  // currentUser = () => this.auth.currentUser;

  // *** Auth API - Sign In ***
  // doSignOut = () => this.auth.signOut();

  // *** Auth API - Password Reset ***
  // doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // *** Auth API - Password Update ***
  // doPasswordUpdate = (password) =>
  //   this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
      if (authUser) {
        
        const userRef = this.user(authUser.uid);
        await timeout(1000);
        console.log('i waited')
        const userGet = await userRef.get();
        const dbUser = await userGet.val();
        console.log(dbUser);
        if (dbUser === null) {
          fallback();
        } else {
          if (!Object.prototype.hasOwnProperty.call(dbUser, "roles")) {
            dbUser.roles = {};
          }
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            metadata: authUser.metadata,
            displayName: authUser.displayName,
            providerData: authUser.providerData[0],
            ...dbUser,
          };
          next(authUser);
        }
      } else {
        fallback();
      }
    });

  // *** User API - UID Ref ***
  // user = (uid) => this.db.ref(`users/${uid}`);

  // *** User API - all Users ***
  // users = () => this.db.ref("users");

  // *** Videos API - all videos ***
  // videos = () => this.db.ref("videos");

  // *** Videos API - Single videos ***
  // video = (id) => this.db.ref(`videos/${id}`);

  // *** Organization API - all Organizations ***
  // organisations = () => this.db.ref("organisations");

  // *** Organization API - Single Organizations ***
  // singleOrganisation = (id) => this.db.ref(`organisations/${id}`);

  // orgs = () => this.db.ref("orgs");

  // // *** Storage API - Reference Documentation ***
  // storageRef = () => this.storage.ref();

  // // *** Storage API - Reference File ***
  // fileRef = (path) => this.storage.ref(path);

  // *** Storage API - Listen for Changes ***
  // storage = () => this.storage

  // *** MAIL API ***
  mail = () => this.db.ref("Mail/NewMailQueue");
}

export default Firebase;
