import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import Firebase, { FirebaseContext } from "./Components/Firebase";

import "./index.css";
import "semantic-ui-css/semantic.min.css";

import App from "./Components/App";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
reportWebVitals();
serviceWorker.unregister();
