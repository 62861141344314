import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as ROUTES from "../../Constants/routes";
import { Container, Header, Icon, Segment } from "semantic-ui-react";

const Home = lazy(() => import("../Home"));
const Contact = lazy(() => import("../Contact"));
const ResponsiveContainer = lazy(() => import("../Navigation"));
const NoMatch = lazy(() => import("../NoMatch"));
const Footer = lazy(() => import("../Footer"));
const Hero = lazy(() => import("../Hero"));

const renderLoader = () => {
  return (
    <Container fluid textAlign="center">
      <Segment fluid="true" basic style={{ paddingTop: "20vh" }}>
        <Header as="h1" icon>
          <Icon name="sync" loading />
          Loading
        </Header>
      </Segment>
    </Container>
  );
};

const Routes = (props) => {
  const darkCookie = document.cookie.split('; ').find(row => row.startsWith('dark='));
  const isDark = typeof darkCookie == "undefined" ? false : darkCookie.split("=")[1];
  const [dark, setDark] = React.useState(isDark === "true" ? true:false);
  const [language, setLanguage] = React.useState("EN");
  const toggleDark = () => {
    const CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() + 1);
    document.cookie = `dark=${!dark}; expires=${CookieDate.toUTCString()};SameSite=strict; Secure`;
    setDark(!dark);
  };
  const changeLanguage = (e, { value }) => setLanguage(value);
  return (
    <Suspense fallback={renderLoader()}>
      <Router>
        <ResponsiveContainer
          dark={dark}
          toggleDark={toggleDark}
          language={language}
          changeLanguage={changeLanguage}
        >
          <Switch>
            <Route exact path={ROUTES.HOME}>
              <Home dark={dark} language={language} />
            </Route>
            {/* <Route exact path={ROUTES.ABOUT}>
              <Home dark={dark} toggleDark={this.toggleDark} language={language} />
            </Route> */}
            <Route exact path={ROUTES.CONTACT}>
              <Contact dark={dark} language={language} />
            </Route>
            <Route exact path="/hero" component={Hero} />
            <Route>
              <NoMatch />
            </Route>
          </Switch>
          <Footer dark={dark} language={language} />
        </ResponsiveContainer>
      </Router>
    </Suspense>
  );
};
export default React.memo(Routes);
